import { getAvailableProductsCategoriesIncludingAny } from './../store/products/reducers';
import React from 'react'
import { getBankData, getIsBusiness, getIsLogged, getUserId, getUserName, getVerified } from '../store/login/reducers'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Translation from '../components/global/Translation'
import { getBuyItems, getBuyItemsExpansionsAvailableList, getBuyItemsTableInfo } from '../store/buy/reducers'
import { deleteItemFromBuy, fetchBuyItems, fetchBuyItemsFromUser, updateQtyItemFromBuy } from '../store/buy/actions'
import Button from '../components/global/Button'
import confirm from '../components/global/confirm'
import LocalizedLink from '../components/global/LocalizedLink'
import Select from 'react-select'
import {
    getBooleanOptions,
    getRarityOptions,
    getAvailableLangsIncludingAny,
    getAvailableStatusIncludingAny,
    getAvailableProductsCategories
} from '../store/products/reducers'
import { fetchUserPublicInfoById } from '../store/user/actions'
import { getUserPublicInfo } from '../store/user/reducers'
import { addToCart } from '../store/cart/actions'
import { getIsAdding } from '../store/cart/reducers'
import Alerts from '../components/alerts/Alerts'
import { showLoginModal } from '../store/ui/actions'
import { RenderBuyItemMobile } from '../components/buy/list/RenderBuyItemMobile'
import Modal from '../components/global/Modal'
import debounce from 'lodash/debounce'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getDefaultProductCategoryByCurrentContext } from '../store/products/CollectableContext'

const mapStateToProps = (state) => {
    return {
        buyItems: getBuyItems(state),
        buyTableInfo: getBuyItemsTableInfo(state),
        availableLangs: getAvailableLangsIncludingAny(state),
        availableStatus: getAvailableStatusIncludingAny(state),
        productsCategoriesOptions: getAvailableProductsCategoriesIncludingAny(state),
        booleanOptions: getBooleanOptions(state),
        expansionOptions: getBuyItemsExpansionsAvailableList(state),
        rarityOptions: getRarityOptions(state),
        userListInfoDistinctCurrentUser: getUserPublicInfo(state),
        addingToCart: getIsAdding(state),
        userIdLogged: getUserId(state),
        userName: getUserName(state),
        isLogged: getIsLogged(state),
        verified: getVerified(state),
        bankData: getBankData(state),
        isBusiness: getIsBusiness(state),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const userToFilter = ownProps.match.params.id

    return {
        fetchDistinctUserPublicInfo: () => {
            dispatch(fetchUserPublicInfoById(userToFilter))
        },
        fetchBuyItems: (newPage, pageSize, filters) => {
            if (userToFilter) {
                dispatch(fetchBuyItemsFromUser(userToFilter, newPage, pageSize, filters))
            } else {
                dispatch(fetchBuyItems(newPage, pageSize, filters))
            }
        },
        deleteItemFromBuy: (itemId) => {
            return dispatch(deleteItemFromBuy(itemId))
        },
        updateQtyItemFromBuy: (itemId, qty) => {
            return dispatch(updateQtyItemFromBuy(itemId, qty))
        },
        addToCart: (productId, qty, locale) => {
            return dispatch(addToCart(productId, qty, locale))
        },
        onShowUpLogin: () => {
            dispatch(showLoginModal())
        }
    }
}
const confirmDeleteItem = (itemId, item, onDeleteItem, intl) => {
    return confirm(intl.formatMessage({ id: 'CONFIRM_DELETE_ITEM_FROM_BUY' })).then(
        (confirm) => {
            return onDeleteItem(itemId)
        },
        (cancel) => {
            return Promise.reject()
        }
    )
}

const getDefaultFilters = () => {
    return {
        search: '',
        selectedLang: {
            value: null,
            label: 'Any'
        },
        selectedExpansion: {
            value: null,
            label: 'Any'
        },
        productCategory: getDefaultProductCategoryByCurrentContext(),
        selectedCondition: null,
        priceMin: 0.0,
        priceMax: 0.0,
        isFoil: {
            value: null,
            label: 'Any'
        },
        isSigned: {
            value: null,
            label: 'Any'
        },
        isPlayset: {
            value: null,
            label: 'Any'
        },
        isAltered: {
            value: null,
            label: 'Any'
        }
    }
}
class BuyItemsScreen extends React.Component {
    booleanOptions = [
        {
            label: 'Any',
            value: null
        },
        {
            label: 'Yes',
            value: true
        },
        {
            label: 'No',
            value: false
        }
    ]

    state = {
        filters: {
            ...getDefaultFilters()
        }
    }

    constructor() {
        super()
        this.searchBuyItems = debounce(this.searchBuyItems.bind(this), 400)
    }


    componentDidMount() {
        if (!this.isUserListEqualToCurrentLogged()) {
            this.props.fetchDistinctUserPublicInfo()
        }
        this.searchBuyItems();
                const params = new URLSearchParams(this.props.location.search)
                const urlFilterCategory = params.get('filterCategory')
                if (urlFilterCategory) {
                    console.log(this.state, 'estadoou')
                    this.setState(
                        {
                            filters: {
                                ...this.state.filters,
                                productCategory: this.props.productsCategoriesOptions.find((item) => item.value === urlFilterCategory),
                            },
                        },
                        () => console.log(this.state, 'estadoou2')
                    )
                }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.collectableContext !== this.props.collectableContext) {
            this.resetFiltersValue(() => this.searchSaleItems())
        }
    }

    resetFiltersValue(callback = null) {
        this.setState({
            ...this.state,
            filters: {
                ...getDefaultFilters()
            }
        }, () => {
            if (callback) {
                callback()
            }
        })
    }

    isUserListEqualToCurrentLogged() {
        if (!this.props.match.params.id) {
            return true
        }
        //Dont put triple equal on this line
        return this.props.match.params.id && this.props.match.params.id == this.props.userIdLogged
    }

    isUserItemEqualToCurrentLogged(item) {
        return this.props.userIdLogged == item.user_id
    }

    canAddItem() {
        return this.props.isLogged && this.isUserListEqualToCurrentLogged()
    }

    searchBuyItems(page = 1, pageSize = 10) {
        let filters = {}
        Object.entries(this.state.filters).forEach(([key, value]) => {
            if (value) {
                if (typeof value === 'object' && !Array.isArray(value)) {
                    value = value.value
                }
                filters[key] = value
            }
        })
        this.props.fetchBuyItems(page, pageSize, filters)
    }

    renderFilters = () => {
        return (
            <form className='card-body' onSubmit={(event) => this.sendFilters(event)}>
                <div className='row'>
                    <div className='col-12 col-lg-3 col-xl-2'>
                        <label htmlFor='name' className='form-label'>
                            <Translation id='name' defaultMessage='Name' />
                        </label>
                        <input
                            type='text'
                            className='form-control'
                            id='name'
                            placeholder={this.props.intl.formatMessage({ id: 'SEARCH_BY_NAME_OR_WHATEVER' })}
                            value={this.state.filters.search}
                            autoComplete='off'
                            onChange={(e) =>
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        search: e.target.value
                                    }
                                }, () => this.searchBuyItems())
                            }
                        />
                    </div>

                    <div className='col-12 col-lg-3 col-xl-2'>
                        <label htmlFor='expansion' className='form-label'>
                            <Translation id='expansion' defaultMessage='Expansion' />
                        </label>
                        <Select
                            options={
                                this.props.expansionOptions
                                // groupBySetName &&
                                // Object.keys(groupBySetName).map((option) => ({
                                //     label: option + ' (' + groupBySetName[option].id.length + ')',
                                //     value: option
                                // }))
                            }
                            value={this.state.filters.selectedExpansion}
                            onChange={(selectedOption) => {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        selectedExpansion: selectedOption
                                    }
                                }, () => this.searchBuyItems())
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select'
                                }) + '...'
                            }
                        />
                    </div>

                    {this.props.isBusiness &&
                        <div className='col-12 col-lg-4 col-xl-1'>
                            <label htmlFor='qty' className='form-label'>
                                <Translation id='PRODUCT_CATEGORY' defaultMessage='Product category' />
                            </label>
                            <Select
                                className=''
                                options={
                                    this.props.productsCategoriesOptions
                                }
                                value={this.state.filters.productCategory}
                                onChange={(selectedOption) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            productCategory: selectedOption
                                        }
                                    }, () => this.searchBuyItems())
                                }}
                                placeholder={
                                    this.props.intl.formatMessage({
                                        id: 'select'
                                    }) + '...'
                                }
                            />
                        </div>
                    }
                    <div className='col-12 col-lg-3 col-xl-1'>
                        <label htmlFor='qty' className='form-label'>
                            <Translation id='language' defaultMessage='Language' />
                        </label>
                        <Select
                            className=''
                            options={
                                this.props.availableLangs
                                // groupByLanguage &&
                                // Object.keys(groupByLanguage).map((option) => ({
                                //     label: option + ' (' + groupByLanguage[option].id.length + ')',
                                //     value: option
                                // }))
                            }
                            value={this.state.filters.selectedLang}
                            onChange={(selectedOption) => {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        selectedLang: selectedOption
                                    }
                                }, () => this.searchBuyItems())
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select'
                                }) + '...'
                            }
                        />
                    </div>
                    <div className='col-12 col-lg-3 col-xl-2'>
                        <label htmlFor='qty' className='form-label'>
                            <Translation id='condition' defaultMessage='Condition' />
                        </label>
                        <Select
                            className=''
                            options={this.props.availableStatus}
                            value={this.state.filters.selectedCondition}
                            onChange={(selectedOption) => {
                                this.setState(
                                    {
                                        filters: {
                                            ...this.state.filters,
                                            selectedCondition: selectedOption
                                        }
                                    }, () => this.searchBuyItems()
                                )
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select'
                                }) + '...'
                            }
                        />
                    </div>

                    <div className='col-12 col-lg-3 col-xl-2'>
                        <div className='d-flex flex-row'>
                            <div>
                                <label className='form-label'>Price Min</label>
                                <input
                                    type='number'
                                    value={this.state.filters.priceMin}
                                    min={0.0}
                                    step={0.01}
                                    className='form-control'
                                    id='minPrice'
                                    onChange={(event) => {
                                        this.setState({
                                            filters: {
                                                ...this.state.filters,
                                                priceMin: event.target.value
                                            }
                                        }, () => this.searchBuyItems())
                                    }}
                                />
                            </div>
                            <div>
                                <label className='form-label'>Price Max</label>
                                <input
                                    type='number'
                                    value={this.state.filters.priceMax}
                                    min={0.0}
                                    step={0.01}
                                    className='form-control'
                                    id='maxPrice'
                                    onChange={(event) => {
                                        this.setState({
                                            filters: {
                                                ...this.state.filters,
                                                priceMax: event.target.value
                                            }
                                        }, () => this.searchBuyItems())
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-2 col-xl-1'>
                        <label htmlFor='foil' className='form-label'>
                            <Translation id='foil' defaultMessage='Foil' />
                        </label>
                        <Select
                            className=''
                            options={this.booleanOptions}
                            value={this.state.filters.isFoil}
                            onChange={(selectedOption) => {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        isFoil: selectedOption
                                    }
                                }, () => this.searchBuyItems())
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select'
                                }) + '...'
                            }
                        />
                    </div>
                    <div className='col-12 col-lg-2 col-xl-1'>
                        <label htmlFor='signed' className='form-label'>
                            <Translation id='signed' defaultMessage='Signed' />
                        </label>
                        <Select
                            className=''
                            options={this.booleanOptions}
                            value={this.state.filters.isSigned}
                            onChange={(selectedOption) => {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        isSigned: selectedOption
                                    }
                                }, () => this.searchBuyItems())
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select'
                                }) + '...'
                            }
                        />
                    </div>
                    <div className='col-12 col-lg-2 col-xl-1'>
                        <label htmlFor='playset' className='form-label'>
                            <Translation id='playset' defaultMessage='Playset' />
                        </label>
                        <Select
                            className=''
                            options={this.booleanOptions}
                            value={this.state.filters.isPlayset}
                            onChange={(selectedOption) => {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        isPlayset: selectedOption
                                    }
                                }, () => this.searchBuyItems())
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select'
                                }) + '...'
                            }
                        />
                    </div>
                    <div className='col-12 col-lg-2 col-xl-1'>
                        <label htmlFor='isAltered' className='form-label'>
                            <Translation id='altered' defaultMessage='Altered' />
                        </label>
                        <Select
                            className=''
                            options={this.booleanOptions}
                            value={this.state.filters.isAltered}
                            onChange={(selectedOption) => {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        isAltered: selectedOption
                                    }
                                }, () => this.searchBuyItems())
                            }}
                            placeholder={
                                this.props.intl.formatMessage({
                                    id: 'select'
                                }) + '...'
                            }
                        />
                    </div>
                    <div className='col-12 col-lg-3 col-xl-1'>
                        <label htmlFor='clear' className='form-label'>
                            {/* <Translation id="clear_the_filters" defaultMessage="Empty" /> */}
                            &nbsp;
                        </label>
                        <Button
                            icon='trash'
                            secondary
                            onClick={() => {
                                this.resetFiltersValue(
                                    () => this.searchBuyItems(1, 10)
                                )
                            }}
                        >
                            <Translation id='clear' defaultMessage='Clear' />
                        </Button>
                    </div>
                </div>
            </form>
        )
    }


    render() {

        if (this.props.isLogged && this.isUserListEqualToCurrentLogged() && !this.props.bankData?.validated) {
            return (
                <div className='col-12'>
                    <Alerts alert={'FILL_FINANCIAL_INFO_TO_TRADE'} status={'warning'} />
                </div>
            )
        }

        const buyItems = this.props.buyItems ?? []

        const title = this.isUserListEqualToCurrentLogged() ? 'My buy list' : `${this.props.userListInfoDistinctCurrentUser?.name || ''} buy list`

        return (
            <div>
                <div className="container-fluid">
                    <div className="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="col-12 col-md-4">
                            <h2>{title}</h2>
                        </div>
                        {this.canAddItem() && (
                            <div className="col-12 col-md-3 col-lg-2">
                                <LocalizedLink routeId={'ROUTE_USER_DASHBOARD'} params={{ view: 'add-to-buy', id: '' }}>
                                    <Button icon="plus-circle">
                                        <Translation id="NEW_ITEM" defaultMessage="New item" />
                                    </Button>
                                </LocalizedLink>
                            </div>
                        )}
                    </div>
                </div>

                <div className="container-fluid">
                    <h4 className="card-title">
                        <Translation id="filters" defaultMessage="Filters" />
                    </h4>
                    <div className="mt-3 mb-3">
                        <div className="d-none d-lg-block">{this.renderFilters()}</div>
                        <div className="d-lg-none row">
                            <div className="col-8">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    placeholder={this.props.intl.formatMessage({ id: 'SEARCH_BY_NAME_OR_WHATEVER' })}
                                    value={this.state.filters.search}
                                    autoComplete="off"
                                    onChange={(e) =>
                                        this.setState(
                                            {
                                                filters: {
                                                    ...this.state.filters,
                                                    search: e.target.value,
                                                },
                                            },
                                            () => this.searchBuyItems()
                                        )
                                    }
                                />
                            </div>

                            <div className="col-4">
                                <Button onClick={() => this.setState({ showFilters: true })}>
                                    <span className="material-icons-outlined" style={{ fontSize: '32px' }}>
                                        filter_alt
                                    </span>
                                </Button>
                            </div>
                            {this.state.showFilters && (
                                <Modal title={<Translation id="filters" defaultMessage="Filters" />} onClose={() => this.setState({ showFilters: false })}>
                                    {this.renderFilters()}
                                </Modal>
                            )}
                        </div>
                    </div>

                    <div className="card-list">
                        <InfiniteScroll
                            dataLength={buyItems.length}
                            next={() => this.props.buyTableInfo.page < this.props.buyTableInfo.pagesCount && this.searchBuyItems(this.props.buyTableInfo.page + 1, 10)}
                            hasMore={this.props.buyTableInfo.page < this.props.buyTableInfo.pagesCount}
                            loader={<h4>Loading...</h4>}
                            endMessage={
                                <p style={{ textAlign: 'center' }}>
                                    <b>You have seen it all</b>
                                </p>
                            }
                        >
                            {buyItems.map((item) => {
                                return (
                                    <RenderBuyItemMobile
                                        key={item.id}
                                        item={item}
                                        intl={this.props.intl}
                                        onShowUpLogin={this.props.onShowUpLogin}
                                        isLogged={this.props.isLogged}
                                        userName={this.props.userName}
                                        bankData={this.props.bankData}
                                        isVerified={this.props.verified}
                                        isUserItemEqualToCurrentLogged={this.isUserListEqualToCurrentLogged(item)}
                                        updateQty={(id, qty) => {
                                            this.props.updateQtyItemFromBuy(id, qty).then(() => this.searchBuyItems())
                                        }}
                                        addToCart={(id, qty) => this.props.addToCart(id, qty, this.props.intl).then(() => this.searchBuyItems())}
                                        deleteSaleItem={(id, original) => confirmDeleteItem(id, original, this.props.deleteItemFromBuy, this.props.intl).then(() => this.searchBuyItems())}
                                    />
                                )
                            })}
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(BuyItemsScreen)))
