import { fetchCredit } from './../store/login/actions'
import React from 'react'

import { Route, Switch } from 'react-router-dom'
import { defineMessages, injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import HomeScreen from '../screens/HomeScreen'
import ProductScreen from '../screens/ProductScreen'
import OrderScreen from '../screens/OrderScreen'
import StaticScreen from '../screens/StaticPages/StaticScreen'
import RegisterScreen from '../screens/RegisterScreen'
import ThanksYouForRegisterScreen from '../screens/ThanksYouForRegisterScreen'
import ThanksYouForVerifyScreen from '../screens/ThanksYouForVerifyScreen'
import ResetPasswordScreeen from '../screens/ResetPasswordScreen'
import SuggestionScreen from '../screens/SuggestionScreen'
import OrderFinishedScreen from '../screens/OrderFinishedScreen'

import DashboardScreen from '../screens/DashboardScreen'
import SummaryScreen from '../screens/SummaryScreen'

import Error404Screen from '../screens/Error404Screen'
import { getIsLogged, getUserType, getVerified } from '../store/login/reducers'
import { connect } from 'react-redux'

import Layout from './layout/Layout'
import MyProfileScreen from '../screens/MyProfileScreen'
import PublicProfileScreen from '../screens/PublicProfileScreen'
import { loginRequired } from '../store/login/actions'
import { fetchCart } from '../store/cart/actions'
import MaintenanceScreen from '../screens/MaintenanceScreen'
import { getMaintenanceMode } from '../store/maintenance-mode/reducers'
import ResendVerificationEmailScreen from '../screens/ResendVerificationEmailScreen'
import AdvancedSearchScreen from '../screens/AdvancedSearchScreen'
import { fetchMyNotifications } from '../store/notifications/actions'
import { fetchPendingTasks } from '../store/pending-tasks/actions'
import PrivateRoute from './routes/PrivateRoute'
import { fetchChats } from '../store/chats/actions'
import VerifyUserScreen from '../screens/VerifyUserScreen'
import PublicRoute from './routes/PublicRoute'



defineMessages({
    ROUTE_HOME: {
        id: 'ROUTE_HOME',
        description: 'ROUTE_HOME',
        defaultMessage: 'ROUTE_HOME',
    },
    ROUTE_NEWS: {
        id: 'ROUTE_NEWS',
        description: 'ROUTE_NEWS',
        defaultMessage: 'ROUTE_NEWS',
    },
    ROUTE_PRODUCT: {
        id: 'ROUTE_PRODUCT',
        description: 'ROUTE_PRODUCT',
        defaultMessage: 'ROUTE_PRODUCT',
    },
    ROUTE_CART: {
        id: 'ROUTE_CART',
        description: 'ROUTE_CART',
        defaultMessage: 'ROUTE_CART',
    },
    ROUTE_REGISTER: {
        id: 'ROUTE_REGISTER',
        description: 'ROUTE_REGISTER',
        defaultMessage: 'ROUTE_REGISTER',
    },
    ROUTE_THANK_FOR_REGISTER_PAGE: {
        id: 'ROUTE_THANKS_YOU_FOR_REGISTER_PAGE',
        description: 'ROUTE_THANKS_YOU_FOR_REGISTER_PAGE',
        defaultMessage: 'ROUTE_THANKS_YOU_FOR_REGISTER_PAGE',
    },
    ROUTE_THANKS_YOU_FOR_VERIFY: {
        id: 'ROUTE_THANKS_YOU_FOR_VERIFY',
        description: 'ROUTE_THANKS_YOU_FOR_VERIFY',
        defaultMessage: 'ROUTE_THANKS_YOU_FOR_VERIFY',
    },
    ROUTE_RESEND_VERIFICATION_MAIL_PAGE: {
        id: 'ROUTE_RESEND_VERIFICATION_MAIL_PAGE',
        description: 'ROUTE_RESEND_VERIFICATION_MAIL_PAGE',
        defaultMessage: 'ROUTE_RESEND_VERIFICATION_MAIL_PAGE',
    },
    ROUTE_STATIC_PAGE: {
        id: 'ROUTE_STATIC_PAGE',
        description: 'ROUTE_STATIC_PAGE',
        defaultMessage: 'ROUTE_STATIC_PAGE',
    },
    ROUTE_USER_DASHBOARD: {
        id: 'ROUTE_USER_DASHBOARD',
        description: 'ROUTE_USER_DASHBOARD',
        defaultMessage: 'ROUTE_USER_DASHBOARD',
    },
    ROUTE_USER_PROFILE: {
        id: 'ROUTE_USER_PROFILE',
        description: 'ROUTE_USER_PROFILE',
        defaultMessage: 'ROUTE_USER_PROFILE',
    },
    ROUTE_RESET_PASSWORD: {
        id: 'ROUTE_RESET_PASSWORD',
        description: 'ROUTE_RESET_PASSWORD',
        defaultMessage: 'ROUTE_RESET_PASSWORD',
    },
    ROUTE_ORDER: {
        id: 'ROUTE_ORDER',
        description: 'ROUTE_ORDER',
        defaultMessage: 'ROUTE_ORDER',
    },
    ROUTE_ORDER_FINISHED: {
        id: 'ROUTE_ORDER_FINISHED',
        description: 'ROUTE_ORDER_FINISHED',
        defaultMessage: 'ROUTE_ORDER_FINISHED',
    },
    ROUTE_PUBLIC_USER_PROFILE: {
        id: 'ROUTE_PUBLIC_USER_PROFILE',
        description: 'ROUTE_PUBLIC_USER_PROFILE',
        defaultMessage: 'ROUTE_PUBLIC_USER_PROFILE',
    },
    ROUTE_SUGGESTIONS: {
        id: 'ROUTE_SUGGESTIONS',
        description: 'ROUTE_SUGGESTIONS',
        defaultMessage: 'ROUTE_SUGGESTIONS',
    },
    ROUTE_ADVANCED_SEARCH: {
        id: 'ROUTE_ADVANCED_SEARCH',
        description: 'ROUTE_ADVANCED_SEARCH',
        defaultMessage: 'ROUTE_ADVANCED_SEARCH',
    },
    ROUTE_VERIFY_USER: {
        id: 'ROUTE_VERIFY_USER',
        description: 'ROUTE_VERIFY_USER',
        defaultMessage: 'ROUTE_VERIFY_USER',
    }
})

const AdminRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (rest.isLogged && rest.userType === 'ADMIN' ? <Component {...props} /> : <Error404Screen />)} />
)
const mapStateToProps = (state) => {
    return {
        isLogged: getIsLogged(state),
        userType: getUserType(state),
        maintenanceMode: getMaintenanceMode(state),
        verified: getVerified(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCart: () => {
            dispatch(fetchCart())
        },
        fetchNotifications: () => {
            dispatch(fetchMyNotifications());
        },
        fetchPendingTasks : () => {
            dispatch(fetchPendingTasks())
        },
        fetchChats: () => {
            dispatch(fetchChats());
        },
        loginRequired: () => {
            dispatch(loginRequired())
        },
        fetchCredit: () => {
            dispatch(fetchCredit())
        },
    }
}

class Routes extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props)
       
    }

    componentDidMount() {
        window.onfocus = () => {
            this.props.fetchCart();
            this.props.fetchCredit();
        }
        if (this.props.isLogged) {
            this.props.fetchNotifications();
            this.props.fetchPendingTasks();
            this.props.fetchChats();
            this.props.fetchCredit()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
        if (!prevProps.isLogged && this.props.isLogged) {
            this.props.fetchNotifications();
            this.props.fetchPendingTasks();
            this.props.fetchChats();
        }
    }

    render() {
        const { intl, maintenanceMode } = this.props
        return (
            <Layout>
                <Switch>
                    {maintenanceMode ? <Route component={MaintenanceScreen} /> : null}
                    <Route exact path={intl.formatMessage({ id: 'ROUTE_HOME' })} component={HomeScreen} />
                    <Route exact path={intl.formatMessage({ id: 'ROUTE_PRODUCT' })} component={ProductScreen} />
                    <Route exact path={intl.formatMessage({ id: 'ROUTE_ORDER' })} component={OrderScreen} />
                    <Route exact path={intl.formatMessage({ id: 'ROUTE_STATIC_PAGE' })} component={StaticScreen} />
                    <PublicRoute {...this.props} exact path={intl.formatMessage({ id: 'ROUTE_REGISTER' })} component={RegisterScreen} />
                    <Route exact path={intl.formatMessage({ id: 'ROUTE_VERIFY_USER' })} component={VerifyUserScreen} />
                    <Route {...this.props} exact path={intl.formatMessage({ id: 'ROUTE_USER_DASHBOARD' })} component={DashboardScreen} />
                    <PrivateRoute {...this.props} exact path={intl.formatMessage({ id: 'ROUTE_USER_PROFILE' })} component={MyProfileScreen} />
                    <Route exact path={intl.formatMessage({ id: 'ROUTE_PUBLIC_USER_PROFILE' })} component={PublicProfileScreen} />
                    <Route exact path={intl.formatMessage({ id: 'ROUTE_THANKS_YOU_FOR_REGISTER_PAGE' })} component={ThanksYouForRegisterScreen} />
                    <PrivateRoute {...this.props} exact path={intl.formatMessage({ id: 'ROUTE_RESEND_VERIFICATION_MAIL_PAGE' })} component={ResendVerificationEmailScreen} />
                    <Route exact path={intl.formatMessage({ id: 'ROUTE_THANKS_YOU_FOR_VERIFY' })} component={ThanksYouForVerifyScreen} />
                    <Route exact path={intl.formatMessage({ id: 'ROUTE_RESET_PASSWORD' })} component={ResetPasswordScreeen} />
                    <Route exact path={intl.formatMessage({ id: 'ROUTE_CART' })} component={SummaryScreen} />
                    <Route exact path={intl.formatMessage({ id: 'ROUTE_ORDER_FINISHED' })} component={OrderFinishedScreen} />
                    <PrivateRoute {...this.props} path={intl.formatMessage({ id: 'ROUTE_SUGGESTIONS' })} component={SuggestionScreen} />
                    {/* <Route path={intl.formatMessage({ id: 'ROUTE_CATALOG' })} component={CatalogScreen} /> */}
                    <Route exact path={intl.formatMessage({ id: 'ROUTE_ADVANCED_SEARCH' })} component={AdvancedSearchScreen} />
                    <Route component={Error404Screen} />
                </Switch>
            </Layout>
        )
    }
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Routes)))
