import { push } from 'react-router-redux'
import { getCart } from './../api'
import { fetchCredit } from './../login/actions'
import { byLineId } from './reducers'
import * as api from '../api'
import { getHasFilledShippingAddress, getIsLogged, getToken, getVerified } from '../login/reducers'
import { getLanguage } from '../ui/reducers'

export const addToCart = (itemId, qty = null) => (dispatch, getState) => {
    dispatch({
        type: 'ADD_CART_LINE_REQUEST',
        line: { itemId, qty }
    })

    if (!getIsLogged(getState())) {
        dispatch({
            type: 'LOGIN_REQUIRED',
            callback: addToCart(itemId, qty),
            payload: { statusText: 'LOGIN_REQUIRED' }
        })
        dispatch({ type: 'ADD_CART_LINE_FAILURE', payload: { statusText: null } })
        return
    }

    if (!getHasFilledShippingAddress(getState())) {
        dispatch({
            type: 'SHIPPING_ADDRESS_REQUIRED',
            failureMessage: 'SHIPPING_ADDRESS_REQUIRED'
        })

        dispatch({ type: 'ADD_CART_LINE_FAILURE', payload: { statusText: null } })
        return Promise.reject()
    }
    //REVIEW:: why?
    // if (productId !== null) dispatch(fetchProduct(productId))

    return api
        .addCartLine(itemId, qty, getLanguage(getState()), getToken(getState()))
        .then((response) => {
            dispatch({ type: 'ADD_CART_LINE_SUCCESS', itemId, qty })
            dispatch(fetchCart())
        })
        .catch((error) => {
            // console.log('HOLAAA')
            // console.log(error)
            dispatch({
                type: 'ADD_CART_LINE_FAILURE',
                payload: { statusText: error.response.data.err, values: error.response.data.info }
            })
            api.errorHandler(dispatch, error.response)
        })
}

export const removeCartLine = (lineId) => (dispatch, getState) => {
    return api
        .removeCartLine(lineId, getToken(getState()))
        .then((response) => {
            dispatch({ type: 'REMOVE_CART_LINE', lineId })
            dispatch(fetchCart())
        })
        .catch((error) => {
            dispatch({
                type: 'REMOVE_CART_LINE_FAILED',
                lineId
            })
        })
}
export const removeCartLinesFromSeller = (sellerId) => (dispatch, getState) => {
    return api
        .removeCartLinesFromSeller(sellerId, getToken(getState()))
        .then((response) => {
            dispatch({ type: 'REMOVE_CART_LINE', sellerId })
            dispatch(fetchCart())
        })
        .catch((error) => {
            dispatch({
                type: 'REMOVE_CART_LINE_FAILED',
                sellerId
            })
        })
}
export const emptyCart = () => (dispatch, getState) => {
    return api
        .emptyCart(getToken(getState()))
        .then((response) => {
            dispatch({ type: 'EMPTY_CART' })
        })
        .catch((error) => {
            dispatch({ type: 'EMPTY_CART_FAILED' })
        })
}
export const changeCartLineQty = (lineId, qty) => (dispatch, getState) => {
    const line = byLineId(getState(), lineId)
    //TODO: devolver qty - blocked tambien en el carrito para que esto no este buggeado.
    if (qty > line.item.qty) {
        qty = line.item.qty
    }
    return api
        .changeCartLineQty(lineId, qty, getToken(getState()))
        .then((response) => {
            dispatch(fetchCart())

        })
        .catch((error) => {
            dispatch({
                type: 'CHANGE_CART_LINE_QTY_FAILED',
                lineId,
                qty,
                payload: { statusText: error.response.data.err, values: error.response.data.info }
            })
        })
}

export const fetchCart = () => (dispatch, getState) => {
    const isLogged = getIsLogged(getState())
    const isVerified = getVerified(getState())

    if (!isLogged || !isVerified) {
        return
    }

    dispatch({ type: 'FETCH_CART_REQUEST' })

    return api.getCart(getLanguage(getState()), getToken(getState())).then(
        (response) => {
            dispatch({ type: 'FETCH_CART_SUCCESS', cart: response.data })
        },
        (error) => {
            // console.log(error)
            dispatch({ type: 'FETCH_CART_FAILURE' })
            api.errorHandler(dispatch, error.response)
        }
    )
}

export const changeShippingMethodBySeller = (sellerName, shippingMethod) => (dispatch, getState) => {

    dispatch({ type: 'CHANGE_SHIPPING_METHOD_BY_SELLER_REQUEST' })
    return api.changeShippingMethodBySeller(sellerName, shippingMethod, getToken(getState())).then(
        (response) => {
            dispatch({ type: 'CHANGE_SHIPPING_METHOD_BY_SELLER_SUCCESS', cart: response.data })
            dispatch(fetchCart())
        },
        (error) => {
            // console.log(error)
            dispatch({ type: 'CHANGE_SHIPPING_METHOD_BY_SELLER_FAILURE' })
            api.errorHandler(dispatch, error.response)
        }
    )
}
export const checkout = () => (dispatch, getState) => {
    const isLogged = getIsLogged(getState())
    if (!isLogged) return
    dispatch({ type: 'SUBMIT_CHECKOUT_REQUEST' })
    return api.checkout(getToken(getState())).then(
        (response) => {
            dispatch(push('/checkout-summary/' + response.data.checkoutId))
            dispatch({ type: 'SUBMIT_CHECKOUT_SUCCESS', checkoutResponse: response.data })
            dispatch({ type: 'SHOW_CHECKOUT_MODAL' })
            dispatch(fetchCart())
            dispatch(fetchCredit())
        },
        (error) => {
            let errors = null
            let errorCode = 'ORDER_CHECKOUT_ERROR'
            if (error.response.data.err) {
                errorCode = error.response.data.err
            }
            if (error.response.data.errors) {
                errors = error.response.data.errors
            }

            dispatch({ type: 'SUBMIT_CHECKOUT_FAILURE', errorCode, errors })
            api.errorHandler(dispatch, error.response)
        }
    )
}

export const getCheckoutSummary = (checkoutId) => (dispatch, getState) => {
    const isLogged = getIsLogged(getState())
    if (!isLogged) return
    dispatch({ type: 'GET_CHECKOUT_SUMMARY_REQUEST' })
    return api.getCheckoutSummary(getToken(getState()), checkoutId).then(
        (response) => {
            dispatch({ type: 'GET_CHECKOUT_SUMMARY_SUCCESS', checkoutResponse: response.data })
        },
        (error) => {
            dispatch({ type: 'GET_CHECKOUT_SUMMARY_FAILURE' })
            // api.errorHandler(dispatch, error.response)
        }
    )
}

export const payMyPendingCredit = (url) => (dispatch, getState) => {
    const isLogged = getIsLogged(getState())
    if (!isLogged) return

    dispatch({ type: 'PAY_PENDING_CREDIT_REQUEST' })

    return api.payMyPendingCredit(getToken(getState()), url).then(
        (response) => {
            dispatch({ type: 'PAY_PENDING_CREDIT_SUCCESS' })
            window.location.href = response.data.url
        },
        (error) => {
            dispatch({ type: 'PAY_PENDING_CREDIT_FAILURE' })
            api.errorHandler(dispatch, error.response)
        }
    )
}

export const addCreditByGateway = (url, amount) => (dispatch, getState) => {
    const isLogged = getIsLogged(getState())
    if (!isLogged) return
    dispatch({ type: 'ADD_CREDIT_REQUEST' })
    return api.addCreditByGateway(getToken(getState()), url, amount).then(
        (response) => {
            dispatch({ type: 'ADD_CREDIT_SUCCESS' })
            window.location.href = response.data.url
        },
        (error) => {
            let errors = null
            if (error.response && error.response.status === 422) {
                errors = error.response.data.errors
            }
            dispatch({ type: 'ADD_CREDIT_FAILURE', errors })
        }
    )
}
export const checkVoucher = voucher => (dispatch, getState) => {
    dispatch({ type: 'CART_CHECK_VALID_VOUCHER_REQUEST', voucher })

    return api.checkVoucher(getToken(getState()), voucher).then(
        response => {
            dispatch({ type: 'CART_CHECK_VALID_VOUCHER_SUCCESS', cart: response.data })
        },
        error => {
            dispatch({
                type: 'CART_CHECK_VALID_VOUCHER_FAILURE',
                error: error,
                voucherStatusText: error.response && error.response.data && error.response.data.err,
                isValidVoucher: false
            })
            return Promise.reject()
        }
    )
}

export const removeVoucher = (voucher) => (dispatch, getState) => {
    dispatch({ type: 'CART_REMOVE_VOUCHER_REQUEST', voucher })

    return api.removeVoucher(getToken(getState()), voucher).then(
        (response) => {
            dispatch({ type: 'CART_REMOVE_VOUCHER_SUCCESS', cart: response.data })
        },
        (error) => {
            dispatch({
                type: 'CART_REMOVE_VOUCHER_FAILURE',
                error: error,
                voucherStatusText: error.response && error.response.data && error.response.data.err
            })
            return Promise.reject()
        }
    )
}
