import { push } from 'react-router-redux'
import axios from 'axios'
import { setError } from './error/actions'
import { logout } from './login/actions'
import { getToken } from './login/reducers'
import store from '../index'
import { getCurrentCollectableContext } from './products/reducers'

const ApiLocation = process.env.REACT_APP_API_HOST
axios.defaults.headers.common['Content-Type'] = 'application/json'

axios.interceptors.request.use((config) => {
    const token = getToken(store.getState())
    config.headers.Authorization = `Bearer ${token}`
    config.headers.CollectableContext = getCurrentCollectableContext(store.getState())
    console.log('REQUEST', config.headers)
    return config
})

// User
export const createAccount = (formData) => {
    return axios.post(ApiLocation + '/api/register', formData)
}

export const resendVerificationEmail = (name) => {
    return axios.post(ApiLocation + '/api/email/verification-notification', { name })
}

export const login = (username, password, locale) => {
    let loginData = {
        login: username,
        password: password,
        lang: locale,
    }

    return axios.post(ApiLocation + '/api/login', loginData)
}

export const recoverPassword = (email) => {
    return axios.post(ApiLocation + '/api/forgot-password', { email: email })
}

export const resetPassword = (formData) => {
    return axios.post(ApiLocation + '/api/reset-password ', formData)
}

export const verifyAccount = (urlVerification, currentLoggedUserId) => {
    return axios.post(urlVerification, {
        currentLoggedUserId
    })
}

// personal info

export const updatePersonalInformation = (token, formData) => {
    return axios.put(ApiLocation + '/api/profile/personal-information', formData, {
        headers: { Authorization: `Bearer ${token}` },
    })
}

export const changePassword = (token, formData) => {
    return axios.put(ApiLocation + '/api/profile/change-password', formData, {
        headers: { Authorization: `Bearer ${token}` },
    })
}

export const updateBusinessConfig = (token, formData) => {
    return axios.post(ApiLocation + '/api/profile/business-config', formData, {
        headers: { Authorization: `Bearer ${token}` },
    })
}

export const updateShippingInformation = (token, formData) => {
    return axios.post(ApiLocation + '/api/profile/shipping-information', formData, {
        headers: { Authorization: `Bearer ${token}` },
    })
}

export const updateFinancialInformation = (token, formData) => {
    return axios.post(ApiLocation + '/api/profile/financial-information', formData, {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const setBuyCredit = (token, buyCredit) => {
    let body = { buyCredit }
    return axios.post(ApiLocation + '/api/profile/set-buy-credit', body, {
        headers: { Authorization: `Bearer ${token}` },
    })
}

export const retrieveCredit = (token, retrieveCredit, selectedPaymentMethod, phoneNumber) => {
    let body = { retrieveCredit, selectedPaymentMethod, phoneNumber }
    return axios.post(ApiLocation + '/api/profile/retrieve-credit', body, {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const getCredit = (token) => {
    return axios.get(ApiLocation + '/api/profile/credit', {
        headers: { Authorization: `Bearer ${token}` },
    })
}

export const fetchCreditRetrieveIngress = (token) => {
    return axios.get(ApiLocation + '/api/profile/credit-history', {
        headers: { Authorization: `Bearer ${token}` },
    })
}

// user
export const getBanksOptions = (token) => {
    return axios.get(ApiLocation + '/api/profile/banks-options', { headers: { Authorization: `Bearer ${token}` } })
}

// bank data validation
export const getBankValidationInfo = (token) => {
    return axios.get(ApiLocation + '/api/profile/bank-validation-info', { headers: { Authorization: `Bearer ${token}` } })
}

export const updateBankDataValidation = (token, formData) => {
    return axios.patch(ApiLocation + '/api/profile/bank-validation-info', formData, {
        headers: { Authorization: `Bearer ${token}` },
    })
}

// user public

export const fetchUserPublicInfoById = (userId) => {
    return axios.get(ApiLocation + '/api/user-public-info/' + userId)
}

export const fetchUserPublicSaleItems = (userId) => {
    return axios.get(ApiLocation + '/api/user-public-items/' + userId)
}

export const fetchUserPublicComments = (userId) => {
    return axios.get(ApiLocation + '/api/user-public-comments/' + userId)
}

// collection

export const fetchProductById = (productId, locale) => {
    return axios.get(ApiLocation + '/api/products/' + productId)
}
export const fetchProductSales = (productId, locale) => {
    return axios.get(ApiLocation + `/api/products/${productId}/sales`)
}

export const fetchProductBuys = (productId, locale, userId = null) => {
    return axios.get(ApiLocation + `/api/products/${productId}/buys?userId=${userId}`)
}

export const fetchExpansionsPreflight = () => {
    return axios.get(ApiLocation + `/api/catalog/card-sets-preflight`)
}

export const fetchCollections = (token, page, pageSize, filters) => {
    let params = {
        page,
        pageSize,
        filters: JSON.stringify(filters),
    }
    return axios.get(ApiLocation + '/api/collection', {
        headers: { Authorization: `Bearer ${token}` },
        params,
    })
}

export const fetchCollectionById = (token, collectionId) => {
    return axios.get(ApiLocation + `/api/collection/${collectionId}`, {
        headers: { Authorization: `Bearer ${token}` },
    })
}

export const deleteItemFromCollection = (token, itemId) => {
    return axios.delete(ApiLocation + '/api/collection/' + itemId, {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const updateQtyItemFromCollection = (token, itemId, qty) => {
    return axios.put(
        ApiLocation + `/api/collection/${itemId}/${qty}`,
        {},
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    )
}

export const addToCollection = (token, collectionItem) => {
    return axios.post(ApiLocation + '/api/collection', collectionItem, {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const editItemFromCollection = (token, collectionItem) => {
    return axios.put(ApiLocation + `/api/collection/${collectionItem.id}`, collectionItem, {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const fetchOrderById = (token, orderId) => {
    return axios.get(ApiLocation + `/api/order/${orderId}`, {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const changeOrderStatus = (token, orderId, action, payload) => {
    let body = { action, payload }
    return axios.post(ApiLocation + `/api/order/${orderId}/order-status`, body, {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const fetchOrders = (token) => {
    return axios.get(ApiLocation + '/api/order', {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const fetchSales = (token) => {
    return axios.get(ApiLocation + '/api/order/sales', {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const fetchBuys = (token) => {
    return axios.get(ApiLocation + '/api/order/buys', {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const fetchSaleItems = (token, page, pageSize, filters) => {
    let params = {
        page,
        pageSize,
        filters: JSON.stringify(filters),
    }
    return axios.get(ApiLocation + '/api/sale', {
        headers: { Authorization: `Bearer ${token}` },
        params,
    })
}

export const fetchSaleItemsFromUser = (token, userToFilter, page, pageSize, filters) => {
    let params = {
        page,
        pageSize,
        filters: JSON.stringify(filters),
    }
    return axios.get(ApiLocation + `/api/sale-from-user/${userToFilter}`, {
        headers: { Authorization: `Bearer ${token}` },
        params,
    })
}

export const fetchSaleItemById = (token, itemId) => {
    return axios.get(ApiLocation + `/api/sale/${itemId}`, {
        headers: { Authorization: `Bearer ${token}` },
    })
}

export const deleteItemFromSale = (token, itemId) => {
    return axios.delete(ApiLocation + '/api/sale/' + itemId, {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const updateQtyItemFromSale = (token, itemId, qty) => {
    return axios.put(
        ApiLocation + `/api/sale/${itemId}/${qty}`,
        {},
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    )
}

export const addToSale = (token, saleItem) => {
    return axios.post(ApiLocation + '/api/sale', saleItem, {
        headers: { Authorization: `Bearer ${token}` },
    })
}

export const editItemFromSale = (token, saleItem) => {
    return axios.put(ApiLocation + `/api/sale/${saleItem.id}`, saleItem, {
        headers: { Authorization: `Bearer ${token}` },
    })
}

export const storeItemImagesFromSale = (token, saleItemId, images) => {
    return axios.post(ApiLocation + `/api/sale/${saleItemId}/images`, images, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    })
}

export const updatePriceFromPriceProvider = (token, provider, operation, value) => {
    return axios.post(
        ApiLocation + '/api/sale/update-price-from-price-provider',
        {
            provider,
            operation,
            value,
        },
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    )
}

export const fetchBuyItems = (token, page, pageSize, filters, device = 'desktop') => {
    let params = {
        page,
        pageSize,
        filters: JSON.stringify(filters),
    }
    return axios.get(ApiLocation + '/api/buy', {
        headers: { Authorization: `Bearer ${token}` },
        params,
    })
}

export const fetchBuyItemsFromUser = (token, userToFilter, page, pageSize, filters) => {
    let params = {
        page,
        pageSize,
        filters: JSON.stringify(filters),
    }
    return axios.get(ApiLocation + `/api/buy-from-user/${userToFilter}`, {
        headers: { Authorization: `Bearer ${token}` },
        params,
    })
}

export const fetchBuyItemById = (token, itemId) => {
    return axios.get(ApiLocation + `/api/buy/${itemId}`, {
        headers: { Authorization: `Bearer ${token}` },
    })
}

export const deleteItemFromBuy = (token, itemId) => {
    return axios.delete(ApiLocation + '/api/buy/' + itemId, {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const updateQtyItemFromBuy = (token, itemId, qty) => {
    return axios.put(
        ApiLocation + `/api/buy/${itemId}/${qty}`,
        {},
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    )
}
export const addToBuy = (token, buyItem) => {
    return axios.post(ApiLocation + '/api/buy', buyItem, {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const editItemFromBuy = (token, buyItem) => {
    return axios.put(ApiLocation + `/api/buy/${buyItem.id}`, buyItem, {
        headers: { Authorization: `Bearer ${token}` },
    })
}

export const addCartLine = (productId, qty, locale, token) => {
    let body = {
        productId,
        qty,
        lang: locale,
    }
    return axios.post(ApiLocation + '/api/cart/lines', body, {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const getCart = (locale, token) => {
    return axios.get(ApiLocation + `/api/cart`, {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const checkVoucher = (token, voucher) => {
    return axios.post(
        ApiLocation + `/api/cart/validate-voucher`,
        {
            voucher,
        },
        {
            headers: { Authorization: `Bearer ${token}` },
        }
    )
}
export const removeVoucher = (token, voucher) => {
    return axios.delete(ApiLocation + `/api/cart/voucher/${voucher}`, {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const checkout = (token) => {
    let body = {}
    return axios.post(ApiLocation + '/api/order/checkout', body, {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const getCheckoutSummary = (token, checkoutId) => {
    return axios.get(ApiLocation + '/api/checkout-summary/' + checkoutId, {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const payMyPendingCredit = (token,url) => {
    let body = { redirectUrl: url }
    console.log('body', url, body)
    return axios.post(ApiLocation + '/api/payments/pay-my-pending-credit', body, {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const addCreditByGateway = (token, url,amount) => {
    let body = { redirectUrl: url,amount }
    return axios.post(ApiLocation + '/api/payments/add-credit', body, {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const changeCartLineQty = (lineId, newQty, token, ) => {
    let body = { qty: newQty }

    return axios.patch(ApiLocation + `/api/cart/lines/${lineId}`, body, {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const changeShippingMethodBySeller = (sellerName, shippingMethod, token) => {
    let body = { shippingMethod, seller: sellerName }
    return axios.patch(ApiLocation + '/api/cart/shipping-method', body, { headers: { Authorization: `Bearer ${token}` } })
}
export const removeCartLine = (lineId, token) => {
    return axios.delete(ApiLocation + `/api/cart/lines/${lineId}`, {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const removeCartLinesFromSeller = (sellerId, token) => {
    return axios.delete(ApiLocation + `/api/cart/lines/seller/${sellerId}`, {
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const emptyCart = () => {
    return axios.delete(ApiLocation + `/api/cart`)
}

export const fetchChat = (orderId, token) => {
    return axios.get(ApiLocation + '/api/chats/' + orderId, { headers: { Authorization: `Bearer ${token}` } })
}

export const fetchChats = (token, params) => {
    return axios.get(ApiLocation + '/api/chats', { headers: { Authorization: `Bearer ${token}` }, params })
}

export const sendMessage = (message, chatId, token) => {
    let messageData = {
        message,
        chatId,
    }
    return axios.post(ApiLocation + '/api/chats/create-message', messageData, {
        headers: { Authorization: `Bearer ${token}` },
    })
}

export const uploadAttachmentMessage = (file, chatId, token) => {
    var formData = new FormData()
    formData.append('file', file)
    formData.append('chatId', chatId)

    return axios.post(ApiLocation + '/api/chats/store-multimedia-message', formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    })
}

export const createChat = (body, token) => {
    return axios.post(ApiLocation + '/api/chats/create-chat', body, { headers: { Authorization: `Bearer ${token}` } })
}

export const sendSuggestion = (token, data) => {
    return axios.post(ApiLocation + `/api/suggestions-mailbox`, data, {
        headers: { Authorization: `Bearer ${token}` },
    })
}

export const fetchPendingTasks = (token) => {
    return axios.get(ApiLocation + `/api/pending-tasks`, {
        headers: { Authorization: `Bearer ${token}` },
    })
}

export const fetchMyNotifications = (token) => {
    return axios.get(ApiLocation + `/api/notifications`, {
        headers: { Authorization: `Bearer ${token}` },
    })
}

export const errorHandler = (dispatch, error, codesToRedirectErrorPage = []) => {
    if (!error) {
        dispatch(setError('NETWORK_ERROR'))
        dispatch(push('/error'))
    } else {
        if (error.status === 500) {
            dispatch(push('/error'))
            localStorage.clear()
            dispatch(logout(true))
        } else if ([400, 422].includes(error.status)) {
        } else if (error.status === 401) {
            dispatch(logout(true))
            localStorage.clear()
            dispatch({ type: 'LOGIN_REQUIRED', payload: { statusText: error.data.err || error.response } })
        } else if (error.status === 403 && error.data.err === 'USER_NOT_VERIFIED') {
            dispatch({ type: 'USER_NOT_VERIFIED' })
        } else if (error.status === 404) {
            if (error.data.err === 'SHIPPING_ADDRESS_NOT_FOUND') {
                dispatch({ type: 'SHIPPING_ADDRESS_NOT_FOUND' })
            }
            if (codesToRedirectErrorPage.includes(error.data.err)) {
                dispatch(push('/error'))
            }
        } else if (error.status === 503 && error.data.err === 'MAINTENANCE_MODE_ON') {
            dispatch({ type: 'RESET_UI' })
            dispatch({ type: 'MAINTENANCE_MODE_ON' })
        } else {
            dispatch(push('/error'))
        }
        //400 Gentile
        //404 url not found
    }
}

export const checkStatus = (response) => {
    if (!response.ok) {
        var error = new Error(response.statusText)
        error.response = response
        throw error
    }

    return response.json()
}
