import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { defineMessages, injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Button from '../components/global/Button'
import businessDisable from '../assets/img/register/business-disabled.png'
import businessEnableable from '../assets/img/register/business-enabled.png'
import individualDisable from '../assets/img/register/individual-disabled.png'
import individualEnable from '../assets/img/register/individual-enabled.png'
import Translation from '../components/global/Translation'
import { setNestedObjectValues, useFormik } from 'formik'
import * as Yup from 'yup'
import { createAccount, verifyAccount } from '../store/login/actions'
import * as ui from '../store/ui/actions'
import LocalizedLink from '../components/global/LocalizedLink'
import { getCreateAccountErrors, getIsLoadingLogin, getUserId, getUserName, getVerified } from '../store/login/reducers'
import { SubheaderAlerts } from '../components/subheader/SubHeader'
import Spinner from '../components/global/Spinner'

defineMessages({
    CANNOT_VERIFY_DISTINCT_USER: {
        id: 'CANNOT_VERIFY_DISTINCT_USER',
        description: 'CANNOT_VERIFY_DISTINCT_USER',
        defaultMessage: 'You cannot verify user distinct of your user'
    },
    USER_VERIFY_EXPIRED_LINK: {
        id: 'USER_VERIFY_EXPIRED_LINK',
        description: 'USER_VERIFY_EXPIRED_LINK',
        defaultMessage: 'Expired link'
    }
})

const VerifyUserScreen = (props) => {
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(true)

    const userName = useSelector((state) => getUserName(state))

    useEffect(async () => {
        const params = new URLSearchParams(props.location.search)
        const url = params.get('token')

        if (!url) {
            props.history.push('/')
            return
        }

        dispatch(verifyAccount(url)).then(() => {
            setIsLoading(false)
        })
    }, [])

    if (isLoading) {
        return <Spinner isLoading={true}></Spinner>
    }

    return (
        <React.Fragment>
            <div>
                <div className='container-fluid registerForm mb-5' id='registerForm'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className=''>
                                <div className='q-top d-none d-xl-block'>
                                    <i className='fas fa-thumbs-up' style={{ padding: '0 15px' }}></i>
                                    Welcome! Your account is verified!
                                </div>
                                <div className='row my-3'>
                                    <div className='col-11 offset-1 lab'>
                                        <h2 >What´s up {userName}!</h2>
                                        <p className='col-10 mt-3'>
                                            Hey Buddy! Welcome to the Cards & Collections community.</p>
                                        <p className='col-10 mt-3'>
                                            You have just completed your account verification!!</p>

                                        <h3 className='col-10 mt-3'>Enjoy the experience !</h3>

                                        <LocalizedLink routeId='ROUTE_HOME'>
                                            <Button icon="eye" className='mt-4 col-6 col-lg-4'>
                                                <Translation id="TAKE_LOOK_CATALOG" defaultMessage="Take a look our catalog" />
                                            </Button>
                                        </LocalizedLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default withRouter(injectIntl(connect()(VerifyUserScreen)))
