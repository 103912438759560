//status
import pristine from '../../assets/img/icons/product/condition/pristine.png'
import poor from '../../assets/img/icons/product/condition/poor.png'
import good from '../../assets/img/icons/product/condition/good.png'
import mint from '../../assets/img/icons/product/condition/mint.png'
import nearMint from '../../assets/img/icons/product/condition/near_mint.png'
import nearMintOrMint from '../../assets/img/icons/product/condition/near_mint_or_mint.png'
import excellent from '../../assets/img/icons/product/condition/excellent.png'
import excellentOrNearMint from '../../assets/img/icons/product/condition/excellent_or_near_mint.png'
import veryGood from '../../assets/img/icons/product/condition/very_good.png'
import veryGoodOrExcellent from '../../assets/img/icons/product/condition/very_good_or_excellent.png'

//lang
import spanishFlag from '../../assets/img/icons/product/locale/es.png'
import englishFlag from '../../assets/img/icons/product/locale/en.png'
import frenchFlag from '../../assets/img/icons/product/locale/fr.png'
import italianFlag from '../../assets/img/icons/product/locale/it.png'
import deutchFlag from '../../assets/img/icons/product/locale/de.png'
import portugueseFlag from '../../assets/img/icons/product/locale/pt.png'
import chineseSFlag from '../../assets/img/icons/product/locale/zhcn.png'
import chineseTFlag from '../../assets/img/icons/product/locale/zhtw.png'
import koreanFlag from '../../assets/img/icons/product/locale/kr.png'
import russianFlag from '../../assets/img/icons/product/locale/ru.png'
import japaneseFlag from '../../assets/img/icons/product/locale/jp.png'
import { COLLECTABLE_CONTEXT_MTG, COLLECTABLE_CONTEXT_LORCANA } from './CollectableContext'
export const initState = {
    collectableContext: COLLECTABLE_CONTEXT_MTG,
    isLoadingProduct: false,
    fetchedProducts: [],
    productSales: {},
    productBuys: {},
    error: null,
    isLoadingSales: false,
    saleItemsCurrentPage: null,
    saleItemsLastPage: null,
    isLoadingBuys: false,
    sampleProduct: {
        img: 'https://images-na.ssl-images-amazon.com/images/I/51qT4cyqIUL._AC_.jpg',
        name: 'lightning bolt',
        edition: 'mtg10',
    },
    productsCategories: {
        [COLLECTABLE_CONTEXT_MTG]: [
            {
                label: 'Single',
                value: 'mtg_single',
            },
            {
                label: 'Sealed',
                value: 'mtg_sealed',
            },
            {
                label: 'Accessory',
                value: 'product_accessory',
            },
        ],
        [COLLECTABLE_CONTEXT_LORCANA]: [
            {
                label: 'Single',
                value: 'lorcana_single',
            },
            /* Por ahora no tenemos lorcana sealed*/
            // {
            //     label: 'Sealed',
            //     value: 'lorcana_sealed'
            // },
            {
                label: 'Accessory',
                value: 'product_accessory',
            },
        ],
    },
    availableLangs: [
        { label: 'English', value: 'en', img: englishFlag, flagValue: 'gb' },
        { label: 'Japanese', value: 'jp', img: japaneseFlag, flagValue: 'jp' },
        { label: 'Chinese-S', value: 'ch-s', img: chineseSFlag, flagValue: 'cn' },
        { label: 'Chinese-T', value: 'ch-t', img: chineseTFlag, flagValue: 'tw' },
        { label: 'Korean', value: 'kr', img: koreanFlag, flagValue: 'kr' },
        { label: 'Spanish', value: 'es', img: spanishFlag, flagValue: 'es' },
        { label: 'French', value: 'fr', img: frenchFlag, flagValue: 'fr' },
        { label: 'Italian', value: 'it', img: italianFlag, flagValue: 'it' },
        { label: 'German', value: 'de', img: deutchFlag, flagValue: 'de' },
        { label: 'Portuguese', value: 'pt', img: portugueseFlag, flagValue: 'pt' },
        { label: 'Russian', value: 'ru', img: russianFlag, flagValue: 'ru' },
    ],
    availableStatus: [
        { numberValue: 1, label: 'Poor', value: 'poor', img: poor, badgeColor: '#ff6669' },
        { numberValue: 2, label: 'Good', value: 'good', img: good, badgeColor: '#ffc466' },
        { numberValue: 3, label: 'Very Good', value: 'very_good', img: veryGood, badgeColor: '#ffc466' },
        { numberValue: 4, label: 'Very Good/Excellent', value: 'very_good_or_excellent', img: veryGoodOrExcellent, badgeColor: '#ffc466' },
        { numberValue: 5, label: 'Excellent', value: 'excellent', img: excellent, badgeColor: '#5ABDB7' },
        { numberValue: 6, label: 'Excellent/Near Mint', value: 'excelent_or_nearmint', img: excellentOrNearMint, badgeColor: '#5ABDB7' },
        { numberValue: 7, label: 'Near Mint', value: 'nearmint', img: nearMint, badgeColor: '#62BD5A' },
        { numberValue: 8, label: 'Near Mint/Mint', value: 'nearmint_or_mint', img: nearMintOrMint, badgeColor: '#62BD5A' },
        { numberValue: 9, label: 'Mint', value: 'mint', img: mint, badgeColor: '#62BD5A' },
        { numberValue: 10, label: 'Pristine', value: 'pristine', img: pristine, badgeColor: '#62BD5A' },
    ],
    rarityOptions: [
        {
            label: 'Any',
            value: null,
        },
        {
            label: 'Common',
            value: 'common',
        },
        {
            label: 'Uncommon',
            value: 'uncommon',
        },
        {
            label: 'Rare',
            value: 'rare',
        },
        {
            label: 'Mythic',
            value: 'mythic',
        },
        {
            label: 'Specia',
            value: 'specia',
        },
        {
            label: 'Bonus',
            value: 'bonus',
        },
    ],
    booleanOptions: [
        {
            label: 'Any',
            value: null,
        },
        {
            label: 'Yes',
            value: 1,
        },
        {
            label: 'No',
            value: 0,
        },
    ],
    productFieldsByCategory: {
        mtg_single: ['expansion', 'language', 'status', 'foil', 'signed', 'playset', 'altered', 'graded', 'comments', 'image_front', 'image_back'],
        mtg_sealed: ['expansion', 'language', 'status', 'comments', 'image_front', 'image_back'],
        lorcana_single: ['expansion', 'language', 'status', 'foil', 'signed', 'altered', 'graded', 'comments', 'image_front', 'image_back'],
        product_accessory: ['expansion', 'language', 'status', 'comments', 'image_front', 'image_back'],
    },
}

const products = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_PRODUCT_REQUEST':
            return { ...state, error: null, isLoadingProduct: true }
        case 'FETCH_PRODUCT_SUCCESS':
            return { ...state, fetchedProducts: [action.product].concat(...state.fetchedProducts), isLoadingProduct: false }
        case 'FETCH_PRODUCT_FAILURE':
            return { ...state, error: action.response, isLoadingProduct: false }
        case 'FETCH_PRODUCT_SALES_REQUEST':
            return { ...state, isLoadingSales: true }
        case 'FETCH_PRODUCT_SALES_FAILURE':
            return { ...state, isLoadingSales: false }
        case 'FETCH_PRODUCT_SALES_SUCCESS':
            return Object.assign({}, state, {
                isLoadingSales: false,
                productSales: { ...state.productSales, [action.productId]: action.salesByProduct },
                // productSales: { ...state.productSales, [action.productId]: Object.assign([],state.productSales[action.productId]?state.productSales[action.productId].concat(action.salesByProduct) : action.salesByProduct) },
                // productsId: [action.productId].concat(...state.productsFavourite.productsId),
                // saleItemsCurrentPage: action.saleItemsCurrentPage,
                // saleItemsLastPage: action.saleItemsLastPage,
            })
        case 'FETCH_PRODUCT_BUYS_REQUEST':
            return { ...state, isLoadingBuys: true }
        case 'FETCH_PRODUCT_BUYS_FAILURE':
            return { ...state, isLoadingBuys: false }
        case 'FETCH_PRODUCT_BUYS_SUCCESS':
            return Object.assign({}, state, { isLoadingBuys: false, productBuys: { ...state.productBuys, [action.productId]: action.buysByProduct } })
        case 'SET_COLLECTABLE_CONTEXT':
            return Object.assign({}, state, { collectableContext: action.context})

        default:
            return state
    }
}

export const getCurrentCollectableContext = (state) => {
    return state.products.collectableContext
}

export const getError = (state) => {
    return state.products.error
}

export const byProductId = (state, id) => {
    let found = state.products.fetchedProducts.find((item) => {
        return item.id == id
    })
    // console.log(found)
    return found
}
export const getProductSales = (state, id) => {
    if (state.products.productSales && !state.products.productSales[id]){
        return []
    }
     return state.products.productSales && state.products.productSales[id] 
}
export const getProductBuys = (state, id) => {
    if (state.products.productBuys && !state.products.productBuys[id]) {
        return []
    }
    return state.products.productBuys && state.products.productBuys[id]
}
export const getFetchedProducts = (state) => {
    return state.products.fetchedProducts
}
export const getAvailableProductsCategories = (state) => {
    return state.products.productsCategories[state.products.collectableContext]
}
export const getAvailableProductsCategoriesIncludingAny = (state) => {
        let availableProductCategories = [{ label: 'Any', value: null, img: '' }, ...state.products.productsCategories[state.products.collectableContext]]

        return availableProductCategories
}
export const getProductFieldsByCategory = (state) => {
    return state.products.productFieldsByCategory
}

export const getAvailableLangs = (state) => {
    return state.products.availableLangs
}
export const getAvailableLangsIncludingAny = (state) => {
    let langsForFilters = [{ label: 'Any', value: null, img: '' }, ...state.products.availableLangs]
    return langsForFilters
}
export const getAvailableStatus = (state) => {
    return state.products.availableStatus
}

export const getAvailableStatusIncludingAny = (state) => {
    return [{ label: 'Any', value: null, img: '' }, ...state.products.availableStatus];
}

export const getIsLoadingSalesItems = (state) => {
    return state.products.isLoadingSales || state.products.isLoadingBuys
}

export const getIsLoadingProduct = state => state.products.isLoadingProduct

export const getSaleItemsCurrentPage = (state) => {
    return state.products.saleItemsCurrentPage
}
export const getSaleItemsLastPage = (state) => {
    return state.products.saleItemsLastPage
}
export const getRarityOptions = (state) => {
    return state.products.rarityOptions;
}

export const getBooleanOptions = (state) => {
    return state.products.booleanOptions;
}

export default products
